import Cookies from 'js-cookie';


const PermisssionGate = ({ children, permissions, user }) => {
    const userPermissions = Cookies.get('perfil');
    if (
        permissions
            .some(permission => {
                return userPermissions.includes(permission)
            })
    ) {
        return children
    }

}

export default PermisssionGate