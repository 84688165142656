// URL da api
const baseURL = 'https://chat.conexaoia.digital'
const baseURLEvolution = 'https://api.conexaoia.digital '

//Headers
var myHeaders = new Headers();
myHeaders.append("X-API-KEY", "F14C7D7625414A3E5DA1811349667");

//Headers evolution
var myHeadersevolution = new Headers();
myHeadersevolution.append("apikey", "B6D711FCDE4D4FD5936544120E713976");

//request para GET
var requestOptionsGET = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
};

//request para GET evolution
var requestOptionsGETEvolution = {
    method: 'GET',
    headers: myHeadersevolution,
    redirect: 'follow'
};

export const all_db = async (endpoint) => {
    try {
        const response = await fetch(`${baseURL}${endpoint}`, requestOptionsGET);
        if (!response.ok) {
            throw new Error('Erro ao buscar dados');
        }
        const result = await response.json();
        console.log(result);

        const decodedPayload = atob(result.token.split(".")[1]);
        const nomesENumeros = JSON.parse(decodedPayload);
        const data = nomesENumeros.data;
        return data;
    } catch (error) {
        // Trate os erros aqui
        console.error('Erro ao buscar dados:', error);
        throw error;
    }
};

export const verifica_instancia = async (endpoint) => {
    try {
console.log(endpoint);
        const response = await fetch (`${baseURL}${endpoint}`, requestOptionsGETEvolution)
        const result = await response.json();
        console.log(result);

        const decodedPayload = atob(result.token.split(".")[1]);
        const status = JSON.parse(decodedPayload);
        return status
    } catch (error) {
        // Trate os erros aqui
        console.error('Erro ao buscar dados:', error);
        throw error;
    }
};

export const consulta_user = async (endpoint, data) => {
    try {

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("X-API-KEY", "F14C7D7625414A3E5DA1811349667");
        console.log("direto da api", data);


        var requestOptionsPOST = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(data),
            redirect: 'follow'
        };

        const response = await fetch(`${baseURL}${endpoint}`, requestOptionsPOST)
        if (!response.ok) {
            throw new Error('Erro ao buscar dados');
        }
        const result = await response.json();
        console.log("resposta da api ", result);

        // console.log("resposta da api ",response);
        return result


    } catch (error) {
        // Trate os erros aqui
        console.error('Erro ao buscar dados:', error);
        throw error;
    }
};

export const verificar_credenciais = async (endpoint, data) => {
    try {

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("X-API-KEY", "F14C7D7625414A3E5DA1811349667");
        console.log("direto da api", data);


        var requestOptionsPOST = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(data),
            redirect: 'follow'
        };

        const response = await fetch(`${baseURL}${endpoint}`, requestOptionsPOST)
        if (!response.ok) {
            throw new Error('Erro ao buscar dados');
        }
        const result = await response.json();
        console.log("resposta da api ", result);
        

        // console.log("resposta da api ",response);
        return result


    } catch (error) {
        // Trate os erros aqui
        console.error('Erro ao buscar dados:', error);
        throw error;
    }
};


export const des_habilita_cliente = async (endpoint, codCliente) => {
    try {

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("X-API-KEY", "F14C7D7625414A3E5DA1811349667");

        var codXp = JSON.stringify({
            "codClient": codCliente
        });

        var requestOptionsPOST = {
            method: 'POST',
            headers: myHeaders,
            body: codXp,
            redirect: 'follow'
        };

        fetch(`${baseURL}${endpoint}`, requestOptionsPOST)
            .then(response => response.text())
            .then(result => {
                return result
            })
            .catch(error => console.log('error', error));

    } catch (error) {
        // Trate os erros aqui
        console.error('Erro ao buscar dados:', error);
        throw error;
    }
};


