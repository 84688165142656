// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aviso-user{
    bottom: -174px !important;
    left: -430px !important;
    right: auto !important;
}

#formulario{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding-top: 2rem;
}

.header-new-user{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.close{
    font-weight: bold !important;
    color: rgb(52, 49, 49) !important;
    width: 2rem;
}

.close:hover{
    font-weight: 700 !important;
    color: rgb(40, 38, 38) !important;
}`, "",{"version":3,"sources":["webpack://./src/components/menuHome/novoUsuario/novoUsuario.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,uBAAuB;IACvB,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;AAClC;;AAEA;IACI,4BAA4B;IAC5B,iCAAiC;IACjC,WAAW;AACf;;AAEA;IACI,2BAA2B;IAC3B,iCAAiC;AACrC","sourcesContent":[".aviso-user{\n    bottom: -174px !important;\n    left: -430px !important;\n    right: auto !important;\n}\n\n#formulario{\n    display: flex;\n    flex-direction: column;\n    gap: 2rem;\n    padding-top: 2rem;\n}\n\n.header-new-user{\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n}\n\n.close{\n    font-weight: bold !important;\n    color: rgb(52, 49, 49) !important;\n    width: 2rem;\n}\n\n.close:hover{\n    font-weight: 700 !important;\n    color: rgb(40, 38, 38) !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
