import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import MenuDrop from "../../components/menuHome/menuHome";
import Button from "@mui/material/Button";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import logoMessemXp from "../../assets/images/Logo Messem + XP 5.png";
import logoConexao from "../../assets/images/CNXtrader-04.png";
import "./disparoDeMensagens.css"; // Importando o arquivo CSS
import TextField from "@mui/material/TextField";
import MuiAlert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import { verifica_instancia } from '../../api/api';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const DisparoDeMensagens = () => {
  const [mensagem, setMensagem] = useState("");
  const [sucess, setSucess] = React.useState(false);
  const [openMensage, setOpenMensage] = React.useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [caracterMessage, setCaracterMessage] = useState(false);
  const [qrCodeError, setQrCodeError] = useState(false);
  const [semClientes, setSemClientes] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [qrCodeConnected, setQrCodeConnected] = useState(false);
  const [intervalId, setIntervalId] = useState(null);
  const [openErrorMessage, setOpenErrorMessage] = React.useState(false); // Novo estado específico para o alerta de erro de mensagem
  const [openEmptyInput, setOpenEmptyInput] = React.useState(false); // Novo estado para o alerta de campo vazio
  const navigate = useNavigate();

  const handleEmptyInputClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenEmptyInput(false);
  };
  ////////////////////////////////////////////////////////
  const handleCloses = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenMensage(false);

  };
  ////////////////////////////////////////////////////////
  const handleErrorMessageClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenErrorMessage(false);
  };
  ////////////////////////////////////////////////////////
  // Função para verificar o token
  const verificarToken = async () => {
    try {

      const dadosArmazenados = localStorage.getItem("dados");
      const objetoRecuperado = JSON.parse(dadosArmazenados);
      let email = objetoRecuperado;
      const token = Cookies.get('token');
      const data = {
        email: email,
        token: token,
      };

      const apiUrl = "https://chat.conexaoia.digital/consulta_user";
      const contentType = "application/json";

      const senhaAPI = "F14C7D7625414A3E5DA1811349667";

      fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": contentType,
          "X-API-KEY": senhaAPI,
        },
        body: JSON.stringify(data),
      })
        .then((response) => {
          console.log("eresponse", response);
          // const decodedPayload = atob(response.token.split(".")[1]);
          // const nomesENumeros = JSON.parse(decodedPayload)
          // console.log("resposta da api : ", nomesENumeros.token);
          if (response.status != 200) {
            console.log("resposta da consulta_user", response);
            navigate("/");

          }
        })
    } catch (error) {
      console.error('Erro ao verificar o token:', error);
    }
  };

  // Chame essa função onde necessário, por exemplo, ao carregar uma página protegida
  useEffect(() => {

    try {

      const dadosArmazenados = localStorage.getItem("user");
      const objetoRecuperado = JSON.parse(dadosArmazenados);
      let email = objetoRecuperado;
      const token = Cookies.get('token');
      const data = {
        email: email,
        token: token,
      };
      console.log(data,"data");
      console.log(data);
      if (!data || !data.senha) {
        // Exibir mensagem de aviso para preencher todos os campos
        // setInputVazio(true);
        setIsLoading(false); // Ativar o indicador de carregamento
        // setOpen(true);
        return;
      }

      // const clientes = consulta_user('/consulta_user', data);

    } catch (error) {
      // Trate os erros aqui
      console.error('Erro ao buscar e logar dados:', error);
    }
    // try {

    //   const dadosArmazenados = localStorage.getItem("dados");
    //   const objetoRecuperado = JSON.parse(dadosArmazenados);
    //   let email = objetoRecuperado;
    //   const token = Cookies.get('token');
    //   const data = {
    //     email: email,
    //     token: token,
    //   };

    //   const apiUrl = "https://chat.conexaoia.digital/consulta_user";
    //   const contentType = "application/json";

    //   const senhaAPI = "F14C7D7625414A3E5DA1811349667";

    //   fetch(apiUrl, {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": contentType,
    //       "X-API-KEY": senhaAPI,
    //     },
    //     body: JSON.stringify(data),
    //   })
    //     .then((response) => {
    //       console.log("eresponse", response);
    //       // const decodedPayload = atob(response.token.split(".")[1]);
    //       // const nomesENumeros = JSON.parse(decodedPayload)
    //       // console.log("resposta da api : ", nomesENumeros.token);
    //       if (response.status != 200) {
    //         console.log("resposta da consulta_user", response);
    //         navigate("/");

    //       }
    //     })
    // } catch (error) {
    //   console.error('Erro ao verificar o token:', error);
    //   navigate("/");
    // }

  }, []);

  ////////////////////////////////////////////////////////
  const handleClickOpen = async (event) => {
    if (mensagem.length < 1) {
      setOpenEmptyInput(true); // Definir como true para exibir o alerta de campo vazio
      setCaracterMessage(true); // Desativar o indicador de carregamento
      setOpen(false);
      return; // Impede o envio do formulário
    } else if (event) {
      event.preventDefault();
    }
    setIsLoading(true)
    console.log("entramos na requicição do disparo de mensagem");
    // // Exemplo de requisição
    var myHeaders = new Headers();
    myHeaders.append("apikey", "B6D711FCDE4D4FD5936544120E713976");
    const dadosArmazenados = localStorage.getItem("user");
    const objetoRecuperado = JSON.parse(dadosArmazenados);
    // console.log("ta aqui o:", objetoRecuperado);

    const newname = objetoRecuperado;
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    console.log("coletados os dados da instancia", objetoRecuperado);

    let urlBase = "https://api.conexaoia.digital/instance/connect/";
    let email = objetoRecuperado;

    let urlCompleta = urlBase + email;
    console.log(
      "URL : ",
      urlCompleta
    );

    fetch(urlCompleta, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        if (result) {
          var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
          };

          let urlBase =
            "https://api.conexaoia.digital/instance/connectionState/";
          let email = objetoRecuperado;
          let urlCompleta = urlBase + email;

          fetch(urlCompleta, requestOptions)
            .then((response) => response.text())
            .then((resulte) => {
              if (resulte) {
                // let objddddetoJson = JSON.parse(resulte);
                console.log("fami", resulte);
                let objetoJson = JSON.parse(resulte);

                let ppp = objetoJson.state;
                console.log("famiddddddddd", objetoJson.instance.state);
                // console.log("teste de conexao", localStorage.setItem("conexao da instancia : ", objetoJson.instance.state));

                if (objetoJson.instance.state === "connecting") {
                  console.log("entramos no if");
                  setIsLoading(false)
                  setQrCodeError(true)
                } else if (objetoJson.instance.state === "open") {
                  setOpen(true);
                  setIsLoading(false)
                }
                // const nomedobgl =
              }
            })
            .catch((error) => console.log("error", error));
          // handleOpen(); // Abre o modal após receber a resposta
        } else {
          console.error("Erro ao enviar mensagem:", result);
        }
      })
      .catch((error) => console.log("error", error));
    setOpenMensage(true)
    setCaracterMessage(true); // Desativar o indicador de carregamento

  };
  ////////////////////////////////////////////////////////
  const handleClose = () => {
    setOpen(false);
  };
  ////////////////////////////////////////////////////////
  const handleChange = (event) => {
    setMensagem(event.target.value);
  };

  const eviodemansagem = async (event) => {
    event.preventDefault(); // Evita o comportamento padrão de enviar o formulário
    console.log("entramos");
    setIsLoading(true);
    // // Exemplo de requisição
    const apiUrl = "https://chat.conexaoia.digital/send_evolution";
    const contentType = "application/json";
    const senhaAPI = "F14C7D7625414A3E5DA1811349667";
    const dadosArmazenados = localStorage.getItem("user");
    const objetoRecuperado = JSON.parse(dadosArmazenados);
    // let email = objetoRecuperado;
    const dados = {
      instancia: objetoRecuperado,
      mensagem: mensagem,
    }
    // Aqui você pode usar a variável 'mensagem' para enviar ou fazer o que for necessário
    console.log("Mensagem digitada:", dados);


    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": contentType,
        "X-API-KEY": senhaAPI,
      },
      body: JSON.stringify(dados),
    })
      .then((response) => response.text())
      .then((result) => {
        console.log("resposta :", result);
        setIsLoading(false);
        handleClose(false)
        setErrorMessage(true)
        setOpenErrorMessage(true)
      })
      .catch((error) => {
        console.error("Erro na requisição principal:", error);
      });
  };

  return (
    <div className="login">

      <Box className="mensage-box">
        <div className="form">
          <div className="ctt">
            <img
              className="logoMessem"
              src={logoMessemXp}
              alt="Conexão Trade"
            />
            <div className="dropdown">
              <MenuDrop />
            </div>
          </div>

          <span
            className="title-login"
            style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
          >
            <h2 style={{ color: "#4c4c4c", fontSize: "150%" }}>
              Envie sua mensagem{" "}
            </h2>
            <p style={{ fontSize: "97%", color: "#8d8d8d" }}>
              Envie suas ofertas de uma
              <br /> maneira mais pratica.
            </p>
          </span>
          {/* onChange={disparoMensagem} */}
          <div className="container-login ">
            <form id="messageForm" className="login-form">
              {/* <TextareaAutosize
                type="text"
                value={mensagem}
                onChange={handleChange}
                name="mensagem"
                id="mensagem"
                placeholder="E-mail"
                required
              ></TextareaAutosize> */}
              <TextField
                type="text"
                value={mensagem}
                onChange={handleChange}
                name="mensagem"
                multiline
                sx={{ m: 1 }}
                // id="mensagem outlined-textarea"
                placeholder="Digite aqui a sua mensagem"
                rows={6}
              />

              {/* onClick={eviodemansagem} */}
              <Button
                variant="contained"
                disableElevation
                onClick={handleClickOpen}
              >
                Enviar
              </Button>
              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"Envio de mensagem"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Tem certeza que deseja fazer o disparo em massa das
                    mensagens?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Cancelar</Button>
                  <Button onClick={eviodemansagem} autoFocus>
                    Enviar
                  </Button>
                </DialogActions>
              </Dialog>
            </form>
          </div>
        </div>

        <div className="conteainer-secundario">
          <div className="conteainer-filho-secundario">
            <img
              style={{ width: "80%" }}
              src={logoConexao}
              alt="Conexão Trade"
            />
            {/* <div className="cabecalho-mensagem">
              <div className="foto-nome">
                
                <div className="name-user"></div>
              </div>
            </div> */}
            {/* Conteúdo do conteiner secundário */}
          </div>
        </div>
      </Box>
      {errorMessage && (
        <Snackbar
          open={openErrorMessage}
          autoHideDuration={6000}
          onClose={handleErrorMessageClose}
        >
          <Alert
            onClose={handleErrorMessageClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            Mensagens enviadas.
          </Alert>
        </Snackbar>
      )}
      {caracterMessage && (
        <Snackbar
          open={openEmptyInput}
          autoHideDuration={6000}
          onClose={handleEmptyInputClose}
        >
          <Alert
            onClose={handleEmptyInputClose}
            severity="warning"
            sx={{ width: "100%" }}
          >
            Campo de mensagem vazio.
          </Alert>
        </Snackbar>
      )}
      {qrCodeError &&
        <Snackbar
          open={openMensage}
          autoHideDuration={6000}
          onClose={handleCloses}
        >
          <Alert
            onClose={handleCloses}
            severity="error"
            sx={{ width: "100%" }}
          >
            QR Code não conectado, favor se conectar.
          </Alert>
        </Snackbar>
      }
      {semClientes &&
        <Snackbar
          open={openMensage}
          autoHideDuration={6000}
          onClose={handleCloses}
        >
          <Alert
            onClose={handleCloses}
            severity="info"
            sx={{ width: "100%" }}
          >
            Sem clientes na lista de disparo.
          </Alert>
        </Snackbar>
      }
      {isLoading && <CircularProgress className="loading" />}
    </div>
  );
};

export default DisparoDeMensagens;
