// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#messageForm{
    display: flex;
    justify-content: space-between;
}

.box-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 332px;
    background-color: #fff;
    border: 2px solid #000;
    box-shadow: 0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12);
    padding: 32px;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    border-radius: 1rem;
}

.qr-code {
    width: 100%;
    height: 332px;
    gap: 1rem;
    border-radius: 1rem;
    filter: hue-rotate(56deg);
}

.box-modal{
    border-radius: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/components/menuHome/novaInstancia/novaInstancia.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,YAAY;IACZ,sBAAsB;IACtB,sBAAsB;IACtB,qHAAqH;IACrH,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,SAAS;IACT,mBAAmB;IACnB,yBAAyB;AAC7B;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":["#messageForm{\n    display: flex;\n    justify-content: space-between;\n}\n\n.box-box {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    width: 332px;\n    background-color: #fff;\n    border: 2px solid #000;\n    box-shadow: 0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12);\n    padding: 32px;\n    display: flex;\n    flex-direction: column;\n    gap: 2rem;\n    border-radius: 1rem;\n}\n\n.qr-code {\n    width: 100%;\n    height: 332px;\n    gap: 1rem;\n    border-radius: 1rem;\n    filter: hue-rotate(56deg);\n}\n\n.box-modal{\n    border-radius: 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
