import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import MuiAlert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import TextField from "@mui/material/TextField";
import "./novoUsuario.css"
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function NovoUsuario() {
  const [open, setOpen] = React.useState(false);
  const [openMensage, setOpenMensage] = React.useState(true);
  const [errorMessage, setErrorMessage] = useState(false);
  const [showRequiredFieldsError, setShowRequiredFieldsError] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [phoneNumber, setPhoneNumber] = React.useState("");

  const handleCloses = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenMensage(false);
  };

  // const formatPhoneNumber = (value) => {
  //   // Remove todos os caracteres não numéricos
  //   const cleaned = ("" + value).replace(/\D/g, "");

  //   // Formatação do número para (XX) XXXX-XXXX
  //   const match = cleaned.match(/^(\d{0,2})(\d{0,4})(\d{0,4})$/);
  //   if (match) {
  //     setPhoneNumber(
  //       `(${match[1]})${match[2] ? ` ${match[2]}` : ""}${match[3] ? `-${match[3]}` : ""
  //       }`
  //     );
  //   }
  // };

  // const handlePhoneChange = (e) => {
  //   formatPhoneNumber(e.target.value);
  // };

  const newUser = (e) => {
    e.preventDefault();
    const email = e.target.elements.emailNovoUsuario.value;
    const perfil = e.target.elements.perfilNovoUsuario.value;
    const senha = e.target.elements.senhaNovoUsuario.value;
    // const numerod = e.target.elements.numeroNovoUsuario.value;

    // Verificação se os campos obrigatórios estão preenchidos
    if (!email || !perfil || !senha ) {
      setShowRequiredFieldsError(true);
    } else {
      setIsLoading(true); // Ativar o indicador de carregamento

      const data = {
        email: e.target.elements.emailNovoUsuario.value,
        perfil: e.target.elements.perfilNovoUsuario.value,
        senha: e.target.elements.senhaNovoUsuario.value,
        // numero: e.target.elements.numeroNovoUsuario.value,
        token: "0000",
      };

      const numero = data.numero; // Obtendo o número da variável 'data'

      const numeroAntes = 55; // Número que você quer adicionar antes

      // Adicionando 'numeroAntes' ao número obtido de 'data.numero'
      const novoNumero = numeroAntes + parseInt(numero, 10);

      console.log(novoNumero); // Aqui está o novo número formado
      const apiUrl = "https://chat.conexaoia.digital/new_user";

      console.log(data);

      fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-API-KEY": "F14C7D7625414A3E5DA1811349667",
        },
        body: JSON.stringify(data),
      })
        .then((response) => {
          if (response.status === 200) {
            console.log("Novo usuário criado com sucesso", data);
            setIsLoading(false); // Ativar o indicador de carregamento
            setErrorMessage(true);
            setOpenMensage(true);
            setOpen(false);
            const instanceName = data.email;

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("apikey", "B6D711FCDE4D4FD5936544120E713976");

            var raw = JSON.stringify({
              instanceName: instanceName,
              token: instanceName,
              qrcode: true,
            });

            var requestOptions = {
              method: "POST",
              headers: myHeaders,
              body: raw,
              redirect: "follow",
            };

            fetch(
              "https://api.conexaoia.digital/instance/create",
              requestOptions
            )
              .then((response) => response.text())
              .then((result) => console.log(result))
              .catch((error) => console.log("error", error));
          } else {
            console.log(response);
            throw new Error("Erro ao criar novo usuário", response);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  return (
    <div>
      <Button onClick={handleOpen}>Novo Usuario</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="header-new-user">
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Novo Usuario
            </Typography>
            <Button className="close" onClick={handleClose}>X</Button>

          </div>
          <form
            onSubmit={newUser}
            className="dropdown-menu p-4"
            id="formulario"
            method="POST"
          >
            <TextField
              size="small"
              className="input"
              type="text"
              name="emailNovoUsuario"
              placeholder="E-mail"
              label="Seu E-mail:"
              variant="outlined"
            />
            <TextField
              size="small"
              className="input"
              type="text"
              name="senhaNovoUsuario"
              placeholder="Senha"
              label="Sua senha:"
              variant="outlined"
            />
            <TextField
              size="small"
              className="input"
              type="text"
              name="perfilNovoUsuario"
              placeholder="Cargo"
              label="Seu cargo:"
              variant="outlined"
            />
            {/* <TextField
              size="small"
              className="input"
              type="text"
              name="numeroNovoUsuario"
              value={phoneNumber}
              onChange={handlePhoneChange}
              label="Seu telefone:"
              placeholder="(XX) XXXX-XXXX"
              variant="outlined"
            /> */}
            <Button className="btn-login" type="submit" variant="contained">
              Criar Novo Usuário
            </Button>
            {showRequiredFieldsError && (
              <Snackbar className="aviso-user" open={showRequiredFieldsError} autoHideDuration={600}>
                <Alert className="aviso-user"
                  onClose={() => setShowRequiredFieldsError(false)}
                  severity="warning"
                >
                  Por favor, preencha todos os campos obrigatórios.
                </Alert>
              </Snackbar>
            )}
            {isLoading && <CircularProgress className="loading" />}
          </form>
        </Box>
      </Modal>
      {errorMessage && (
        <Snackbar
          open={openMensage}
          autoHideDuration={6000}
          onClose={handleCloses}
        >
          <Alert
            onClose={handleCloses}
            severity="success"
            sx={{ width: "100%" }}
          >
            Cadastro de usuário concluído.
          </Alert>
        </Snackbar>
      )}
    </div>
  );
}
