import React from 'react';
import './App.css'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import LoginPage from './pages/Login/Login';
import HomePage from './pages/Home/disparoDeMensagens';
// import Cookies from 'js-cookie';
import PermissionGate from "../src/permissoes/PermissionsGate"

function App() {

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<LoginPage />} />

          <Route path="/home" element={<HomePage />}/>
          {/* Outras rotas */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
