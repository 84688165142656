import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Login.css"; // Importando o arquivo CSS
import logoMessemXp from "../../assets/images/Logo Messem + XP 5.png";
import logoConexao from "../../assets/images/CNXtrader-04.png";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import MuiAlert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import Cookies from 'js-cookie';
import { verificar_credenciais } from '../../api/api';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const LoginPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [inputVazio, setInputVazio] = useState(false);
  const [open, setOpen] = React.useState(true);

  const navigate = useNavigate();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };


  const verificarCredenciais = async function verificarCredenciaisUser(e) {
    // setIsLoading(true);
    try {

      e.preventDefault();
      const data = {
        email: e.target.elements.emailLogin.value,
        senha: e.target.elements.senhaLogin.value,
      };
      console.log(data);
      if (!data.email || !data.senha) {
        // Exibir mensagem de aviso para preencher todos os campos
        setInputVazio(true);
        setIsLoading(false); // Ativar o indicador de carregamento
        setOpen(true);
        return;
      }

      const clientes = await verificar_credenciais('/verificar_credenciais', data);
      console.log("no login agrdddddddddddddddddddddddddddddddddddddd", clientes);
      const decodedPayload = atob(clientes.token.split(".")[1]);
      const status = JSON.parse(decodedPayload);
      console.log(status);
      if (clientes.mensagem === "Credenciais corretas") {
        Cookies.set('token', status.token);
        Cookies.set('perfil', status.perfil);
        localStorage.setItem("user", JSON.stringify( e.target.elements.emailLogin.value));
        navigate("/home");

      } else {
        setInputVazio(true);
      }

    
    } catch (error) {
      // Trate os erros aqui
      console.error('Erro ao buscar e logar dados:', error);
      setInputVazio(true);
    }

  }

  return (
    <div className="login">


      <div className="login-box">
        <div className="form">
          <div className="ctt">
            <img
              src={logoMessemXp}
              style={{ width: "46%" }}
              alt="Conexão Trade"
            />
            <div className="dropdown">
            </div>
          </div>
          <span
            className="title-login"
            style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
          >
            <h2 style={{ color: "#4c4c4c", fontSize: "150%" }}>
              Acesse sua conta
            </h2>
            <p
              style={{
                fontSize: "97%",
                color: "#8d8d8d",
                marginBottom: "0 !important",
              }}
            >
              Se você possui uma conta, preencha seus dados de acesso à
              plataforma
            </p>
          </span>
          <div className="container-login">
            <form onSubmit={verificarCredenciais} className="login-form">
              <TextField
                size="small"
                className="input"
                type="text"
                name="emailLogin"
                id="emailLogin"
                placeholder="E-mail"
                label="Seu E-mail:"
                variant="outlined"
              />

              <TextField
                size="small"
                className="input"
                type="password"
                name="senhaLogin"
                id="senhaLogin filled-size-small"
                placeholder="Senha"
                label="Sua senha:"
                variant="outlined"
              />
              <Button className="btn-login" type="submit" variant="contained">
                Entrar
              </Button>

              {errorMessage && (
                <Snackbar
                  open={open}
                  autoHideDuration={6000}
                  onClose={handleClose}
                >
                  <Alert
                    onClose={handleClose}
                    severity="error"
                    sx={{ width: "100%" }}
                  >
                    Nome de usuário ou senha incorretos.
                  </Alert>
                </Snackbar>
              )}
              {inputVazio && (
                <Snackbar
                  open={open}
                  autoHideDuration={6000}
                  onClose={handleClose}
                >
                  <Alert
                    onClose={handleClose}
                    severity="error"
                    sx={{ width: "100%" }}
                  >
                    Nome de usuário ou senha incorretos.
                  </Alert>
                </Snackbar>
              )}
              {isLoading && <CircularProgress className="loading" />}
            </form>
          </div>
          {/* <p id="error-message" style={{ color: 'red', display: errorMessage ? 'flex' : 'none' }}>
                    {errorMessage}
                </p> */}
        </div>
        <div
          className="conteainer-secundario"

        >
          <div
            className="conteainer-filho-secundario"

          >
            <img
              className="logoMessem"
              src={logoConexao}
              style={{
                width: "90%", height: "35%",
              }}
              alt="Conexão Trade"
            />

            {/* Conteúdo do conteiner secundário */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
