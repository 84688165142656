import React, { useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Switch from '@mui/material/Switch';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import './listaDeClientes.css'
import { all_db, des_habilita_cliente } from '../../../api/api';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function ApiOficialWpp() {
    const [open, setOpen] = React.useState(false);
    const [caracterMessage, setCaracterMessage] = useState(false);
    const [openMensage, setOpenMensage] = React.useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [clientes, setClientes] = useState([]);
    const [clienteAtualizado, setClienteAtualizado] = useState({});
    const [desabilitaCliente, setDesabilitaCliente] = useState(false);
    const [habilitaCliente, setHabilitaCliente] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [searchText, setSearchText] = useState('');

    const handleClose = () => setOpen(false);
    const handleClosedesabilita = () => setDesabilitaCliente(false);
    const handleClosehabilita = () => setHabilitaCliente(false);

    const handleEmptyInputClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenMensage(false);
    };

    const handleClienteToggle = (event, cliente) => {
        const clienteAtualizado = { ...cliente, ConfirmaEnvio: event.target.checked };
        setClienteAtualizado(clienteAtualizado);
        console.log("trocado", clienteAtualizado);
        if (clienteAtualizado.ConfirmaEnvio === true) {
            setHabilitaCliente(true);
            console.log("deseja desabilitar esse cliente?");
        } else {
            setDesabilitaCliente(true);
            console.log("deseja habilitar esse cliente?");
        }
    };

    const handleOpen = async function clientes() {
        try {
            setIsLoading(true);
            setOpen(true);

            const data = await all_db('/all_db');

            setIsLoading(true);

            console.log(data);
            setClientes(data);
            setIsLoading(false);
        } catch (error) {
            // Trate os erros aqui
            console.error('Erro ao buscar e logar dados:', error);
            setIsLoading(false);
        }
    }

    const des_habilitaClientedoBanco = async function HabilitaClientes() {
        setIsLoading(true);
        try {
            console.log("codclkiente", clienteAtualizado)
            const codCliente = clienteAtualizado.codClient;
            const endpoint = clienteAtualizado.Enviar ? '/desabilita_cliente' : '/habilita_cliente';

            console.log("codclkiente", codCliente)

            const data = await des_habilita_cliente(`${endpoint}`, codCliente);
            const clientes = await all_db('/all_db');
            setClientes(clientes);

            setIsLoading(true);
            // setOpen(true);
            console.log(data);
            // setIsLoading(false);
            setDesabilitaCliente(false);
            setHabilitaCliente(false)
            console.log('Dados recebidos:', data);

        } catch (error) {
            // Trate os erros aqui
            console.error('Erro ao buscar e logar dados:', error);
        }
        const datas = await all_db('/all_db');
        setClientes(datas);

        setIsLoading(false);
    }

    return (
        <div>
            <Button onClick={handleOpen} className="box-modal">Lista de clientes</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="box-box lista-clientes">
                    {isLoading && <CircularProgress className="loading" />}
                    <div className="cabecalho-modal">
                        <h1>Lista de clientes</h1>
                        <Button className="close" onClick={handleClose}>X</Button>

                    </div>

                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Nome</TableCell>
                                    <TableCell>telefone</TableCell>
                                    <TableCell>Cod. XP</TableCell>
                                    <TableCell>Habilitado</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {clientes
                                    .filter((cliente) =>
                                        cliente.codClient.toLowerCase().includes(searchText.toLowerCase())
                                    )
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((cliente, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{cliente.nome}</TableCell>
                                            <TableCell>{cliente.numero}</TableCell>
                                            <TableCell>{cliente.codClient}</TableCell>
                                            <TableCell>
                                                <Switch
                                                    checked={cliente.Enviar === 1 ? true : false}
                                                    onChange={(event) => handleClienteToggle(event, cliente)}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={clientes.filter((cliente) =>
                                cliente.codClient.toLowerCase().includes(searchText.toLowerCase())
                            ).length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={(event, newPage) => setPage(newPage)}
                            onRowsPerPageChange={(event) => {
                                setRowsPerPage(parseInt(event.target.value, 10));
                                setPage(0);
                            }}
                        />
                    </TableContainer>
                </Box>
            </Modal>
            {caracterMessage && (
                <Snackbar
                    open={openMensage}
                    autoHideDuration={6000}
                    onClose={handleEmptyInputClose}
                >
                    <Alert
                        onClose={handleEmptyInputClose}
                        severity="success"
                        sx={{ width: "100%" }}
                    >
                        QR Code conectado.
                    </Alert>
                </Snackbar>
            )}
            {desabilitaCliente && (
                <Dialog
                    open={des_habilitaClientedoBanco}
                    onClose={() => setDesabilitaCliente(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Desabilitar Cliente"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Tem certeza que deseja desabilitar esse cliente para o disparo em massa das
                            mensagens?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClosedesabilita} >Cancelar</Button>
                        <Button onClick={des_habilitaClientedoBanco} autoFocus>
                            Desabilitar
                        </Button>
                    </DialogActions>
                    {isLoading && <CircularProgress className="loading" />}
                </Dialog>
            )}
            {habilitaCliente && (
                <Dialog
                    open={habilitaCliente}
                    onClose={() => setHabilitaCliente(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Habilitar Cliente"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Tem certeza que deseja habilitar esse cliente para o disparo em massa das
                            mensagens?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClosehabilita}>Cancelar</Button>
                        <Button onClick={des_habilitaClientedoBanco} autoFocus>
                            Habilitar
                        </Button>
                    </DialogActions>
                    {isLoading && <CircularProgress className="loading" />}

                </Dialog>
            )}
        </div>
    );
}