import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
// import MenuItem from '@mui/material/MenuItem';
import QrCode from "../../components/menuHome/novaInstancia/novaInstancia";
import DeleteCliente from "../../components/menuHome/removerCliente/removerCliente"
import NovoUsuario from "./novoUsuario/novoUsuario";
import ListaClientes from "./listaDeClientes/listaDeClientes";
import PermissionGate from "../../permissoes/PermissionsGate"

import "./menuHome.css"
export default function MenuHome() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div >
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        className='menu'
      >
        |||
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >


        <QrCode />
        <ListaClientes />
        <DeleteCliente />

        <PermissionGate permissions={[
          'Administrador',
          'teste1@hotmail.com',
          'desenvolvedor',
        ]}>
          <NovoUsuario />

        </PermissionGate>


      </Menu>
    </div>
  );
}