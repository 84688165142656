// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lista-clientes {
    width: 700px !important;
}

.cabecalho-modal{
    display: flex;
    justify-content: space-between;
    margin: 0;
}

.cabecalho-modal h1{
    margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/menuHome/listaDeClientes/listaDeClientes.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,SAAS;AACb;;AAEA;IACI,SAAS;AACb","sourcesContent":[".lista-clientes {\n    width: 700px !important;\n}\n\n.cabecalho-modal{\n    display: flex;\n    justify-content: space-between;\n    margin: 0;\n}\n\n.cabecalho-modal h1{\n    margin: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
