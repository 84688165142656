import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import React, { useState, useEffect } from "react";
import MuiAlert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import "./novaInstancia.css"

// import { useHistory } from 'react-router-dom';
import Home from "../../../pages/Home/disparoDeMensagens"

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function ApiOficialWpp() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [base64Image, setBase64Image] = React.useState(""); // Estado para armazenar a imagem base64
  const [imageUrl, setImageUrl] = useState("");
  const [intervalId, setIntervalId] = useState(null);
  const [caracterMessage, setCaracterMessage] = useState(false);
  const dadosArmazenados = localStorage.getItem("user");
  const objetoRecuperado = JSON.parse(dadosArmazenados);
  const [openMensage, setOpenMensage] = React.useState(false); // Novo estado para o alerta de campo vazio
  // const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const handleEmptyInputClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenMensage(false);
  };

    const disparoOficial = async (event) => {
    if (event) {
      event.preventDefault();
    }
    setIsLoading(true);
    console.log("entramos na requicição");
    // // Exemplo de requisição
    var myHeaders = new Headers();
    myHeaders.append("apikey", "B6D711FCDE4D4FD5936544120E713976");
    const dadosArmazenados = localStorage.getItem("user");
    const objetoRecuperado = JSON.parse(dadosArmazenados);
    // console.log("ta aqui o:", objetoRecuperado);

    const newname = objetoRecuperado;
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    console.log("entramos na requicição", objetoRecuperado);

    let urlBase = "https://api.conexaoia.digital/instance/connect/";
    let email = objetoRecuperado;

    let urlCompleta = urlBase + email;
    console.log(
      "URL : ",
      urlCompleta
    );

    fetch(urlCompleta, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        if (result) {
          let objetoJson = JSON.parse(result);
          setImageUrl(objetoJson.base64); // Define a nova imagem do QR code
          setIsLoading(false); // Desativar o indicador de carregamento após a verificação
          if (objetoJson.instance.state === "connecting") {
            setOpen(true); // Continua o looping
            console.log("entramos no if");
          } else if (objetoJson.instance.state === "open") {
            setOpen(false); // Interrompe o looping
            setCaracterMessage(true);
            setOpenMensage(true);

            // console.log("teste de conexao", localStorage.setItem("conexao da instancia : ", objetoJson.instance.state));

          }
          // var myHeaders = new Headers();
          // myHeaders.append("apikey", "B6D711FCDE4D4FD5936544120E713976");

          // var requestOptions = {
          //   method: "GET",
          //   headers: myHeaders,
          //   redirect: "follow",
          // };

          // let urlBase =
          //   "https://api.conexaoia.digital/instance/connectionState/";
          // let email = objetoRecuperado;
          // let urlCompleta = urlBase + email;

          // fetch(urlCompleta, requestOptions)
          //   .then((response) => response.text())
          //   .then((resulte) => {
          //     if (resulte) {
          //       // let objddddetoJson = JSON.parse(resulte);
          //       console.log("fami", resulte);
          //       let objetoJson = JSON.parse(resulte);

          //       let ppp = objetoJson.state;
          //       console.log("famiddddddddd", objetoJson.instance.state);
          //       // console.log("teste de conexao", localStorage.setItem("conexao da instancia : ", objetoJson.instance.state));

          //       if (objetoJson.instance.state === "connecting") {
          //         setOpen(true); // Continua o looping
          //         console.log("entramos no if");
          //       } else if (objetoJson.instance.state === "open") {
          //         setOpen(false); // Interrompe o looping
          //         setCaracterMessage(true);

          //         // console.log("teste de conexao", localStorage.setItem("conexao da instancia : ", objetoJson.instance.state));

          //       }
          //       // const nomedobgl =
          //     }
          //   })
          // .catch((error) => console.log("error", error));
          // handleOpen(); // Abre o modal após receber a resposta
        } else {
          console.error("Erro ao enviar mensagem:", result);
        }
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (open) {
        disparoOficial({ preventDefault: () => { } });
      }
    }, 15000);

    setIntervalId(interval);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [open]);

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
    if (intervalId) {
      clearInterval(intervalId);
    }
  };

  return (
    <div>
      <Button onClick={handleOpen} className="box-modal">qr code</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="box-box">
          <form onSubmit={disparoOficial} id="messageForm">
            {/* onClick={eviodemansagem} */}
            <Button type="submit" variant="contained" disableElevation>
              Conectar-se com WhatsApp
            </Button>
            <Button className="close" onClick={handleClose}>X</Button>
            {isLoading && <CircularProgress className="loading" />}

          </form>
          {imageUrl && (
            <iframe className="qr-code"
              src={imageUrl}
              alt="Imagem base64"
            />
          )}
        </Box>

      </Modal>

      {/* {errorMessage && (
        <Snackbar
          open={openMensage}
          autoHideDuration={60000}
          onClose={handleCloses}
        >
          <Alert
            onClose={handleCloses}
            severity="success"
            sx={{ width: "100%" }}
          >
            Mensagens enviadas.
          </Alert>
        </Snackbar>
      )} */}
      {caracterMessage && (
        <Snackbar
          open={openMensage}
          autoHideDuration={6000}
          onClose={handleEmptyInputClose}
        >
          <Alert
            onClose={handleEmptyInputClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            QR Code conectado.
          </Alert>
        </Snackbar>
      )}
    </div>
  );
}
