import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import MuiAlert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import TextField from "@mui/material/TextField";
import "./removerCliente.css"
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function RemoverCliente() {
  const [open, setOpen] = React.useState(false);
  const [openMensage, setOpenMensage] = React.useState(true);
  const [openMensages, setOpenMensages] = React.useState(true);
  const [errorMessage, setErrorMessage] = useState(false);
  const [clienteNaoEncontrado, setClienteNaoEncontrado] = useState(false);

  const [showRequiredFieldsError, setShowRequiredFieldsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = React.useState("");

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleCloses = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenMensage(false);
  };

  const handleClosestwo = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenMensages(false);
  };

  const deleteClient = (e) => {
    e.preventDefault();

    const codCliente = e.target.elements.CodCliente.value;
    console.log("é esse aqui ó ", codCliente);

    setIsLoading(true);

    // Exemplo de requisição
    const apiUrl = "https://chat.conexaoia.digital/desabilita_cliente"; // Substitua pela URL da sua API
    const contentType = "application/json"; // Tipo de conteúdo apropriado para a sua API

    // Corpo da solicitação com os valores coletados, incluindo a senha da API
    const data = {
      codClient: codCliente,
    };

    // Configuração da solicitação com a senha da API no cabeçalho
    const senhaAPI = "F14C7D7625414A3E5DA1811349667";

    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": contentType,
        "X-API-KEY": senhaAPI,
      },
      body: JSON.stringify(data), // Enviar o objeto data como JSON
    })
      .then((response) => {
        console.log(response);
      }) // Altere para response.text() para obter o conteúdo da resposta como texto
      .then((result) => {

        const jsonData = JSON.parse(result);
        const decodedPayload = atob(jsonData.token.split(".")[1]);
        const nomesENumeros = JSON.parse(decodedPayload)

        // console.log("resposta", nomesENumeros);
        // if (nomesENumeros.data === "Cliente nao encontrado"); {
        //   console.log("tudo cerrto");
        // }
        // setIsLoading(false);
        // setErrorMessage(true);
        // setOpenMensage(true);
        // setOpen(false);
        // console.log("Conteúdo do Payload:", nomesENumeros);
        // setIsLoading(false);

      })
      .catch((error) => {
        console.error("Erro:", error);
      });

    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-API-KEY": "F14C7D7625414A3E5DA1811349667",
      },
      body: JSON.stringify(data),
    })

      .then((response) => {
        console.log(response);

        if (response.status === 200) {
          console.log("cliente removido com sucesso", response);
          setIsLoading(false);
          setErrorMessage(true);
          setOpenMensage(true);
          setOpen(false);
        } else if (response.status === 201) {
          console.log("cliente nao encontrado", response);
          setIsLoading(false);
          setClienteNaoEncontrado(true)
          // setOpenMensage(true);
          setOpen(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div>
      {/* onClick={handleOpen} */}
      <Button onClick={handleOpen} className="btn-removerclientes">Remover cliente</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="header-new-user">
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Remover cliente
            </Typography>
            <Button className="close" onClick={handleClose}>
              X
            </Button>
          </div>
          <form
            onSubmit={deleteClient}
            className="dropdown-menu p-4"
            id="formulario"
            method="POST"
          >
            <TextField
              size="small"
              className="input"
              type="text"
              name="CodCliente"
              // value={CodCliente}
              label="Codigo XP do cliente"
              placeholder="Codigo XP do cliente"
              variant="outlined"
            />
            <Button className="btn-login" type="submit" variant="contained">
              Remover Cliente
            </Button>
            {showRequiredFieldsError && (
              <Snackbar
                className="aviso-user"
                open={showRequiredFieldsError}
                autoHideDuration={600}
              >
                <Alert
                  className="aviso-user"
                  onClose={() => setShowRequiredFieldsError(false)}
                  severity="warning"
                >
                  Por favor, preencha todos os campos obrigatórios.
                </Alert>
              </Snackbar>
            )}
            {isLoading && <CircularProgress className="loading" />}
          </form>
        </Box>
      </Modal>
      {errorMessage && (
        <Snackbar
          open={openMensage}
          autoHideDuration={6000}
          onClose={handleCloses}
        >
          <Alert
            onClose={handleCloses}
            severity="success"
            sx={{ width: "100%" }}
          >
            Remoção do cliente concluída.
          </Alert>
        </Snackbar>
      )}
      {clienteNaoEncontrado && (
        <Snackbar
          open={openMensages}
          autoHideDuration={6000}
          onClose={handleClosestwo}
        >
          <Alert
            onClose={handleClosestwo}
            severity="info"
            sx={{ width: "100%" }}
          >
            Cliente não encontrado.
          </Alert>
        </Snackbar>
      )}
    </div>
  );
}
