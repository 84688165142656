// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu{
    /* rotate: revert; */
    transform: rotate(90deg) !important;
    color: #425167 !important;
    font-size: 1.2rem !important;
    font-weight: 700 !important;
    /* width: 100%; */
    /* height: 100%; */
}`, "",{"version":3,"sources":["webpack://./src/components/menuHome/menuHome.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,mCAAmC;IACnC,yBAAyB;IACzB,4BAA4B;IAC5B,2BAA2B;IAC3B,iBAAiB;IACjB,kBAAkB;AACtB","sourcesContent":[".menu{\n    /* rotate: revert; */\n    transform: rotate(90deg) !important;\n    color: #425167 !important;\n    font-size: 1.2rem !important;\n    font-weight: 700 !important;\n    /* width: 100%; */\n    /* height: 100%; */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
